import { SvgAttribute } from '#components/shared/svgs/icon.interface'

export const SvgAutomateProcess = (props: SvgAttribute): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="icons"
    viewBox="0 0 51.17 51.17"
    {...props}
  >
    <path
      className="cls-1"
      d="M44.03,0H7.15C3.21,0,0,3.21,0,7.15v26.63C0,37.72,3.21,40.93,7.15,40.93h9.24v1.75c0,1.89-.77,3.74-2.11,5.09l-1.69,1.7c-.29,.29-.37,.72-.22,1.09,.16,.37,.52,.62,.92,.62h24.59c.4,0,.77-.24,.92-.62,.15-.37,.07-.8-.22-1.09l-1.69-1.69c-1.34-1.35-2.11-3.21-2.11-5.09v-1.75h9.24c3.94,0,7.15-3.21,7.15-7.15V7.15C51.17,3.21,47.97,0,44.03,0Zm-8.56,49.17H15.7c1.71-1.72,2.69-4.09,2.69-6.49v-1.75h14.39v1.75c0,2.41,.98,4.77,2.69,6.49Zm13.7-15.39c0,2.84-2.31,5.15-5.15,5.15H7.15c-2.84,0-5.15-2.31-5.15-5.15v-3.24c1.3,1.35,3.13,2.19,5.15,2.19H44.03c2.02,0,3.85-.84,5.15-2.19v3.24Zm0-8.2c0,2.84-2.31,5.15-5.15,5.15H7.15c-2.84,0-5.15-2.31-5.15-5.15V7.15C2,4.31,4.31,2,7.15,2H44.03c2.84,0,5.15,2.31,5.15,5.15V25.59Z"
      fill="currentColor"
    />
  </svg>
)
