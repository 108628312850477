import Image from 'next/image'

import { vercelImageLoader } from '#utils/url'

import globeImg from '../assets/globe.webp'
import { PanelCentralizedContainer } from './components/PanelContainer'

export const GlobePanel = (): JSX.Element => {
  return (
    <PanelCentralizedContainer className="bg-[#122A47] p-4 py-24 md:pt-20">
      <div className="grid w-full grid-cols-1 items-center justify-center gap-y-14 sm:grid-cols-2 sm:gap-x-14 md:gap-x-40">
        <div>
          <p className="q-text-body-base-bold text-primary-300">
            Endless resources
          </p>
          <h2 className="q-text-header-4xl mt-2 text-4xl text-white">
            Buyers around <br className="hidden sm:block" />
            the globe
          </h2>
          <p className="q-text-body-base mt-4 text-gray-200">
            We’re proud to partner with a variety of buyers all over the world.
            Choose where you can deliver and let us handle the rest... including
            shipping costs!
          </p>
        </div>
        <div className="flex items-center justify-center">
          <div className="max-w-[440px]">
            <Image
              loader={vercelImageLoader}
              src={globeImg}
              alt="Globe showing locations of buyers"
            />
          </div>
        </div>
      </div>
    </PanelCentralizedContainer>
  )
}
