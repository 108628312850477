import { BrandsPanel } from './panels/Brands'
import { FaqPanel } from './panels/Faq'
import { FulfilmentPanel } from './panels/Fulfilment'
import { GlobePanel } from './panels/Globe'
import { Hero } from './panels/Hero'
import { PortalPanel } from './panels/Portal'
import { ReadyToGetStarted } from './panels/ReadyToGetStarted'
import { TestimonialsPanel } from './panels/Testimonials'
import { WhoAreYou } from './panels/WhoAreYou'
import { WhySellToUs } from './panels/WhySellToUs'

export const MarketingHomePage = (): JSX.Element => {
  return (
    <>
      <div className="hero-gradient">
        <Hero />
        <WhySellToUs />
      </div>
      <WhoAreYou />
      <PortalPanel />
      <FulfilmentPanel />
      <GlobePanel />
      <BrandsPanel />
      <FaqPanel />
      <TestimonialsPanel />
      <ReadyToGetStarted />
    </>
  )
}
