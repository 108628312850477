import { scrollToTop } from '#src/utils/dom-actions'

import { RegisterButton } from '../panels/RegisterButton'
import { BackToTopCircleArrow } from './components/BackToTopCircleArrow'
import { PanelCentralizedContainer } from './components/PanelContainer'

const content = {
  title: 'Ready to get started?',
  description:
    'Join 1000s of wholesale sellers who’ve grown their business on Qogita.',
  buttonCaption: 'Sign up for free',
}

export const ReadyToGetStarted = (): JSX.Element => {
  return (
    <PanelCentralizedContainer className="relative bg-gray-100 py-24 md:py-24">
      <div className="flex flex-col items-center text-center">
        <h2 className="q-text-header-4xl mb-6 text-center">{content.title}</h2>

        <h3 className="q-text-header-3xl mb-6 max-w-lg text-center text-gray-700">
          {content.description}
        </h3>

        <div className="w-full text-base hover:text-gray-800">
          <RegisterButton>{content.buttonCaption}</RegisterButton>
        </div>
      </div>

      <button className="hidden w-full md:block" onClick={scrollToTop}>
        <BackToTopCircleArrow className="absolute bottom-6 right-6" />
      </button>
    </PanelCentralizedContainer>
  )
}
