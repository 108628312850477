function getEnvironment() {
  const nodeEnvironment = process.env.NODE_ENV

  if (typeof nodeEnvironment !== 'string') {
    throw new TypeError(
      `No 'NODE_ENV' could be used found in environment, value returned '${JSON.stringify(
        nodeEnvironment,
      )}'`,
    )
  }

  const isProductionEnvironment = nodeEnvironment === 'production'
  const isDevelopmentEnvironment = nodeEnvironment === 'development'
  const isTestEnvironment = nodeEnvironment === 'test'

  return {
    isProdEnv: isProductionEnvironment,
    isDevEnv: isDevelopmentEnvironment,
    isTestEnv: isTestEnvironment,
  }
}

export const developmentAdviseError = (message: string): void => {
  const { isProdEnv } = getEnvironment()

  // Bail - we ignore messages in prod!
  if (isProdEnv) return

  // eslint-disable-next-line no-console
  console.error(message)
}

export const developmentDisruptError = (message: string): void => {
  const { isProdEnv } = getEnvironment()

  // Bail - we ignore messages in prod!
  if (isProdEnv) return

  throw new Error(message)
}
