import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import { Anchor } from '@qogita/ui'
import clsx from 'clsx'

import { trackMarketingPageFAQExpanded } from '#src/utils/report/tracking'

import { PanelCentralizedContainer } from './components/PanelContainer'

type DisclosureItemProps = {
  question: string
  children: React.ReactNode
}

const faqContent = [
  {
    question: 'How will Qogita send me orders?',
    answer: (
      <>
        The first notification will come via email and the order details can be
        finalized within our seller portal. For more information on selling with
        Qogita,{' '}
        <Anchor href="https://help.sellers.qogita.com/" target="_blank">
          see here
        </Anchor>
        .
      </>
    ),
  },
  {
    question: 'Who are your customers?',
    answer:
      'All of our buyers are registered businesses with VATs in their local market. These customers are located all over the world and belong to a wide range of industries.',
  },
  {
    question: 'Does Qogita handle shipping costs?',
    answer: 'Yes, ship your goods and we’ll reimburse any associated costs.',
  },
  {
    question: 'Do you require us (the seller) to drop ship for Qogita?',
    answer:
      'No, we will only send you complete, whole orders, that meet your Minimum Order Value. The only difference will be a change of delivery address, belonging to our buyer who placed the order.',
  },
  {
    question: 'Does Qogita purchase stock to hold?',
    answer:
      'No, we place an order against your stock once we’ve received an order from the buyer.',
  },
  {
    question: 'Who handles claims from unhappy buyers?',
    answer:
      'Our internal team handles all claims from buyers who have problems with their orders.',
  },
]

const DisclosureItem = ({
  question,
  children,
}: DisclosureItemProps): JSX.Element => (
  <Disclosure>
    <div className="mt-6 rounded-lg bg-white px-6 py-3">
      <Disclosure.Button
        className="grid w-full grid-cols-[9fr_1fr] gap-4 font-bold"
        onClick={() => {
          trackMarketingPageFAQExpanded({ faqQuestion: question })
        }}
      >
        {({ open }) => (
          <>
            <span className="text-left">{question}</span>

            <div className="flex justify-end align-middle">
              <ChevronDownIcon
                className={clsx('h-6 w-6 text-gray-400', {
                  '-rotate-180': open,
                })}
              />
            </div>
          </>
        )}
      </Disclosure.Button>
      <Disclosure.Panel className="mt-3 text-gray-700">
        {children}
      </Disclosure.Panel>
    </div>
  </Disclosure>
)

export const FaqPanel = (): JSX.Element => {
  return (
    <PanelCentralizedContainer className="mt-6 bg-gray-100 py-24 md:py-24">
      <div className="w-full">
        <h2 className="q-text-header-4xl mb-14 text-center">
          Frequently asked questions
        </h2>
        {faqContent.map(({ question, answer }) => (
          <DisclosureItem key={question} question={question}>
            {answer}
          </DisclosureItem>
        ))}
      </div>
    </PanelCentralizedContainer>
  )
}
