import Image from 'next/image'

import { vercelImageLoader } from '#src/utils/url'

import buildingImg from '../assets/fulfilment/building.svg'
import costsCheckImg from '../assets/fulfilment/costsCheck.svg'
import illustrationsImg from '../assets/fulfilment/illustrations.svg'
import inventoryCheckImg from '../assets/fulfilment/inventoryCheck.svg'
import shipmentsCheckImg from '../assets/fulfilment/shipmentsCheck.svg'
import truckImg from '../assets/fulfilment/truck.svg'
import { PanelCentralizedContainer } from './components/PanelContainer'

const content = [
  {
    image: buildingImg,
    alt: 'Pile of boxes stacked on top of each other',
    text: 'Manage your own inventory',
    icon: inventoryCheckImg,
  },
  {
    image: truckImg,
    alt: 'Truck',
    text: 'Ship goods once they’ve been paid for',
    icon: shipmentsCheckImg,
  },
  {
    image: illustrationsImg,
    alt: 'Shipping paperwork',
    text: 'Reduce outsourced shipping costs',
    icon: costsCheckImg,
  },
]

export const FulfilmentPanel = (): JSX.Element => {
  return (
    <PanelCentralizedContainer className="bg-gray-100 py-24 sm:py-40">
      <div className="w-full">
        <p className="q-text-body-base-bold text-primary-700">Fulfilment</p>
        <h2 className="q-text-header-4xl mt-4">
          We help you sell it, but you ship it
        </h2>
        <h3 className="q-text-header-3xl mt-2">
          Fulfilling orders yourself allows you to:
        </h3>
      </div>
      <div className="mt-14 grid grid-cols-1 gap-x-24 gap-y-8 sm:mt-24 sm:grid-cols-3">
        {content.map(({ image, text, alt, icon }) => (
          <div key={text} className="flex flex-col gap-2">
            <div className="hidden sm:block">
              <Image loader={vercelImageLoader} src={image} alt={alt} />
            </div>
            <div className="border-primary-700 text-primary-700 sm:border-l-[0.5px] sm:pl-6 md:pr-12">
              <div className="flex h-16 w-16 items-center">
                <Image loader={vercelImageLoader} src={icon} alt={alt} />
              </div>
              <p className="text-lg font-bold">{text}</p>
            </div>
          </div>
        ))}
      </div>
    </PanelCentralizedContainer>
  )
}
