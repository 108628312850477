import clsx from 'clsx'
import Image from 'next/image'

import { vercelImageLoader } from '#src/utils/url'

import heroImg from '../assets/hero.svg'
import { PanelSection } from './components/PanelContainer'
import { RegisterButton } from './RegisterButton'

const HERO_ALT =
  'One image of the seller portal on desktop showing orders being received and one image on mobile displaying a revenue graphic'

const content = {
  title: 'Sell, ship, and surpass your competition',
}

function TextAndButton() {
  return (
    <div className="flex flex-col md:translate-y-28">
      <h1
        className={clsx(
          'break-words [hyphens:auto] min-[275px]:[hyphens:none] sm:break-normal', // hyphens for tiny breakpoints
          'q-text-header-5xl mb-8 max-w-md text-center md:mt-0 md:max-w-lg md:text-left',
        )}
      >
        {content.title}
      </h1>
      <RegisterButton className="md:max-w-fit">Start selling</RegisterButton>
    </div>
  )
}

function HeroImage() {
  return (
    <div
      className={clsx(
        'absolute -top-[7px] w-[16rem] min-[320px]:w-[18rem]', // mobile image max size and positioning
        'md:ml-5 md:w-[520px]', // desktop image max sizing and positioning
        `md:top-init md:relative md:block`, // desktop resets
        'md:translate-y-28', // translate to position image under the content
      )}
    >
      <Image loader={vercelImageLoader} src={heroImg} alt={HERO_ALT} priority />
    </div>
  )
}

export const Hero = (): JSX.Element => {
  return (
    <PanelSection
      className={clsx(
        'flex w-full flex-1 flex-col items-center justify-center md:flex-row md:items-start', //  flex
        'pb-16 pt-56 md:pb-5 md:pt-24', // paddings
      )}
    >
      <TextAndButton />
      <HeroImage />
    </PanelSection>
  )
}
