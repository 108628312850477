import clsx from 'clsx'
import Image, { StaticImageData } from 'next/image'

import { vercelImageLoader } from '#utils/url'

import dolceGabbanaLogo from '../assets/brands/dolce-gabbana.webp'
import garnierLogo from '../assets/brands/garnier.webp'
import lattafaLogo from '../assets/brands/lattafa.webp'
import lorealLogo from '../assets/brands/loreal.webp'
import maybellineLogo from '../assets/brands/maybelline.webp'
import niveaLogo from '../assets/brands/nivea.webp'
import oralBLogo from '../assets/brands/oral-b.webp'
import schwarzkopfLogo from '../assets/brands/schwarzkopf.webp'
import theBodyShopLogo from '../assets/brands/the-body-shop.webp'
import versaceLogo from '../assets/brands/versace.webp'
import { PanelCentralizedContainer } from './components/PanelContainer'

interface BrandType {
  name: string
  /*
   * Should this brand be displayed on mobile this flag has to be set to true
   */
  visibleOnMobile: boolean
  logo: StaticImageData
}

const brands: BrandType[] = [
  { name: 'Maybelline', logo: maybellineLogo, visibleOnMobile: true },
  { name: 'Garnier', logo: garnierLogo, visibleOnMobile: true },
  { name: 'Dolce & Gabbana', logo: dolceGabbanaLogo, visibleOnMobile: true },
  { name: 'Nivea', logo: niveaLogo, visibleOnMobile: true },
  { name: 'Versace', logo: versaceLogo, visibleOnMobile: true },
  { name: 'Lattafa', logo: lattafaLogo, visibleOnMobile: true },
  { name: "L'Oréal", logo: lorealLogo, visibleOnMobile: false },
  { name: 'Schwarzkopf', logo: schwarzkopfLogo, visibleOnMobile: false },
  { name: 'Oral-B', logo: oralBLogo, visibleOnMobile: false },
  { name: 'The Body Shop', logo: theBodyShopLogo, visibleOnMobile: false },
]

export const BrandsPanel = (): JSX.Element => {
  return (
    <PanelCentralizedContainer className="py-24 md:py-24">
      <h2 className="text-center text-4xl font-bold">Brands</h2>
      <ul className="mt-14 grid list-none grid-cols-2 gap-4 md:grid-cols-5 md:gap-x-6 md:gap-y-12">
        {brands.map((brand) => (
          <li
            key={brand.name}
            className={clsx(
              'rounded bg-white shadow hover:shadow-lg',
              brand.visibleOnMobile ? 'block' : 'hidden md:block',
            )}
          >
            <div className="flex h-full items-center justify-center p-1">
              <Image
                loader={vercelImageLoader}
                src={brand.logo}
                alt={brand.name}
              />
            </div>
          </li>
        ))}
      </ul>
    </PanelCentralizedContainer>
  )
}
