import { logError } from '@qogita/logging'

import { developmentAdviseError } from './developmentError'

/**
 * @description Selects element and throw error if not found
 */
async function selectNode<T extends Element>(selector: string): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    const target = document.querySelector<T>(selector)

    if (!target) {
      return reject()
    }

    return resolve(target)
  })
}

/**
 * @description Scroll to element and call focus
 */
const scrollToElement = async (
  selector: string,
  options: ScrollIntoViewOptions = {},
): Promise<void> => {
  try {
    const node = await selectNode<HTMLInputElement>(selector)

    node.scrollIntoView({
      behavior: 'smooth',
      ...options,
    })

    node.focus({ preventScroll: true })
  } catch {
    logError(new Error(`Could not scroll to '${selector}'`))
  }
}

export const scrollToTop = async (): Promise<void> => {
  const pageTopSelector = 'body' as const

  try {
    await scrollToElement(pageTopSelector, { behavior: 'smooth' })
  } catch {
    developmentAdviseError(`Could not scroll to '${pageTopSelector}'`)
  }
}

/**
 * @description Escapes focus from input
 */
export const escapeInputFocus = async (selector: string): Promise<void> => {
  try {
    const editingInput = await selectNode<HTMLInputElement>(selector)

    editingInput.blur()
  } catch {
    logError(new Error(`Could not remove focus by onblur using '${selector}'`))
  }
}
