import { SvgAttribute } from '#components/shared/svgs/icon.interface'

/**
 * @description custom homepage icon reserved for scrolling upwards
 */
export function BackToTopCircleArrow(props: SvgAttribute) {
  return (
    <svg
      width="73"
      height="73"
      viewBox="0 0 73 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="36.5" cy="36.9263" r="35.5" stroke="#4338CA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.4393 17.8656C36.0251 17.2798 36.9749 17.2798 37.5607 17.8656L52.5607 32.8656C53.1464 33.4514 53.1464 34.4011 52.5607 34.9869C51.9749 35.5727 51.0251 35.5727 50.4393 34.9869L38 22.5476V54.9263C38 55.7547 37.3284 56.4263 36.5 56.4263C35.6716 56.4263 35 55.7547 35 54.9263V22.5476L22.5607 34.9869C21.9749 35.5727 21.0251 35.5727 20.4393 34.9869C19.8536 34.4011 19.8536 33.4514 20.4393 32.8656L35.4393 17.8656Z"
        fill="currentColor"
      />
    </svg>
  )
}
