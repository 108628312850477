import { SvgAttribute } from '#components/shared/svgs/icon.interface'

export const SvgSecurePayment = (props: SvgAttribute): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="icons"
    viewBox="0 0 55.27 42.98"
    {...props}
  >
    <path
      className="cls-1"
      d="M9.2,34.78h8.2c.55,0,1-.45,1-1s-.45-1-1-1H9.2c-.55,0-1,.45-1,1s.45,1,1,1Z"
      fill="currentColor"
    />
    <path
      className="cls-1"
      d="M9.2,28.63H25.59c.55,0,1-.45,1-1s-.45-1-1-1H9.2c-.55,0-1,.45-1,1s.45,1,1,1Z"
      fill="currentColor"
    />
    <path
      className="cls-1"
      d="M48.12,0H7.15C3.21,0,0,3.21,0,7.15v28.68C0,39.77,3.21,42.98,7.15,42.98H48.12c3.94,0,7.15-3.21,7.15-7.15V7.15C55.27,3.21,52.06,0,48.12,0Zm5.15,35.83c0,2.84-2.31,5.15-5.15,5.15H7.15c-2.84,0-5.15-2.31-5.15-5.15V14.29H53.27v21.54Zm0-23.54H2v-.05H53.27v.05Zm0-2.05H2v-3.1c0-2.84,2.31-5.15,5.15-5.15H48.12c2.84,0,5.15,2.31,5.15,5.15v3.1Z"
      fill="currentColor"
    />
  </svg>
)
