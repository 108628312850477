import { logError } from '@qogita/logging'
import { getStoryblokApi, useStoryblokState } from '@storyblok/react'
import { GetServerSidePropsContext, InferGetServerSidePropsType } from 'next'

import { Page as HomePage } from '#components/pages/index/Page'
import { PublicLayout } from '#src/components/shared/templates/PublicLayout'
import { PageStoryblok } from '#src/types/storyblok-component-types'

const Page = ({
  story: initialStory,
}: InferGetServerSidePropsType<
  typeof getServerSideProps
>): JSX.Element | null => {
  const story = useStoryblokState<PageStoryblok>(initialStory, {
    preventClicks: true,
  })

  return (
    <PublicLayout pageBlok={story?.content || null}>
      <HomePage />
    </PublicLayout>
  )
}

export const getServerSideProps = async ({
  query,
}: GetServerSidePropsContext) => {
  const isInStoryblokIframe = Boolean(query._storyblok)

  const storyblokApi = getStoryblokApi()

  try {
    const { data } = await storyblokApi.get(
      `cdn/stories/sellers-qogita-com/app-layout`,
      {
        version: isInStoryblokIframe ? 'draft' : 'published',
        resolve_links: 'url',
        resolve_relations: ['page.footer'],
      },
    )

    return {
      props: {
        story: data.story,
      },
    }
  } catch {
    logError(`Couldn't fetch storyblok data for home page`)

    return {
      props: {
        story: null,
      },
    }
  }
}

Page.options = {
  public: true,
}

export default Page
