import { NextSeo } from 'next-seo'

import { env } from '#src/env.mjs'

const TITLE = 'Qogita | Sellers'
const DESCRIPTION =
  'We are the wholesale you know, but better. Enabling sellers to reach buyers across the globe with no added complexity or costs.'

type Props = {
  title?: string
  description?: string
}

const BASE_URL = env.NEXT_PUBLIC_BASE_URL

export function Seo({
  title = TITLE,
  description = DESCRIPTION,
}: Props): JSX.Element {
  return (
    <NextSeo
      canonical={BASE_URL}
      defaultTitle={title}
      description={description}
      openGraph={{
        url: BASE_URL,
        description: DESCRIPTION,
        site_name: title,
        title,
        locale: 'en-gb',
        images: [
          {
            url: 'https://images.prod.qogita.com/files/downloads/logo-qogita.png?w=1200&h=630&fit=crop',
            width: 1200,
            height: 630,
            alt: 'Qogita logo',
          },
        ],
      }}
      title={title}
      titleTemplate={title}
      twitter={{
        cardType: 'summary',
        handle: 'https://twitter.com/qogitahq',
        site: 'https://twitter.com/qogitahq',
      }}
    />
  )
}
