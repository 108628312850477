import clsx from 'clsx'

import { PanelCentralizedContainer } from '#components/pages/index/panels/components/PanelContainer'
import { SvgAttribute } from '#components/shared/svgs/icon.interface'
import { SvgAutomateProcess } from '#src/components/pages/index/assets/whySellToUs/SvgAutomateProcess'
import { SvgExpandYourReach } from '#src/components/pages/index/assets/whySellToUs/SvgExpandYourReach'
import { SvgItsFree } from '#src/components/pages/index/assets/whySellToUs/SvgItsFree'
import { SvgSecurePayment } from '#src/components/pages/index/assets/whySellToUs/SvgSecurePayment'

interface Content {
  title: string
  subtitle: string
  items: {
    title: string
    description: string
    Icon: React.FC<SvgAttribute>
  }[]
}

const content: Content = {
  title: 'Why sell via Qogita?',
  subtitle:
    'We give you the power of the world’s largest suppliers by exposing you to thousands of potential buyers.',
  items: [
    {
      title: 'Reach',
      description:
        'Expand your reach instantly. Our platform enables merchants to sell and ship to different buyers all over the world, without adding extra complexity. Listing goods through Qogita will increase order consistency and prevent overstocking.',
      Icon: SvgExpandYourReach,
    },
    {
      title: 'Automate',
      description:
        'Reduce your manual order processing time. Qogita heavily reduces the human effort, and therefore cost, required to fulfill an order by unifying order processing into one place, our seller portal.',
      Icon: SvgAutomateProcess,
    },
    {
      title: 'Secure payment',
      description:
        'Get paid as soon as you ship the goods. No more waiting for payments, leading to irregular cash flow issues.',
      Icon: SvgSecurePayment,
    },
    {
      title: 'It’s free',
      description:
        'There isn’t a fee to sell via our platform, we’re here to help you grow your business quickly and save money.',

      Icon: SvgItsFree,
    },
  ],
}

export const WhySellToUs = () => {
  return (
    <PanelCentralizedContainer className="py-24 pb-24">
      <div className="flex max-w-lg flex-col self-start px-0 pb-14">
        <h2 className="q-text-header-4xl">{content.title}</h2>
        <p className="q-text-body-base mt-4 text-gray-700">
          {content.subtitle}
        </p>
      </div>

      <div
        className={clsx(
          'grid w-full grid-cols-1 gap-x-6 gap-y-14 md:grid-cols-2 lg:grid-cols-2',
        )}
      >
        {content.items.map((item, index) => (
          <div key={index} className="flex max-w-md flex-col">
            <item.Icon className="text-primary-700 h-12 w-12" />

            <h2 className="q-text-body-base-bold mt-4 text-2xl">
              {item.title}
            </h2>

            <p className="q-text-body-base mt-4 max-w-sm text-gray-700">
              {item.description}
            </p>
          </div>
        ))}
      </div>
    </PanelCentralizedContainer>
  )
}
