import clsx from 'clsx'

type PanelSectionProps = {
  children: React.ReactNode
  className?: string
}

export function PanelSection({ children, className }: PanelSectionProps) {
  return (
    <section
      className={clsx(
        'flex flex-col items-center px-4',

        className,
      )}
    >
      {children}
    </section>
  )
}

/**
 * This aligns the styles for all the panels
 * Classnames are exposed for customisation when needed
 */
export function PanelCentralizedContainer({
  children,
  className,
}: PanelSectionProps) {
  return (
    <PanelSection className={className}>
      <div className="flex w-full max-w-5xl flex-col">{children}</div>
    </PanelSection>
  )
}
