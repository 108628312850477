import { ArrowSmallRightIcon } from '@heroicons/react/20/solid'
import { Button } from '@qogita/ui'
import clsx from 'clsx'
import Image from 'next/image'
import { useState } from 'react'

import { trackMarketingPagePortalNextStepClicked } from '#src/utils/report/tracking'
import { vercelImageLoader } from '#utils/url'

import step1Img from '../assets/portal/step1.webp'
import step2Img from '../assets/portal/step2.webp'
import step3Img from '../assets/portal/step3.webp'
import step4Img from '../assets/portal/step4.webp'
import { PanelCentralizedContainer } from './components/PanelContainer'

const steps = [
  {
    title: 'Receive new order',
    description:
      'An order is allocated to you with a deadline. You can decline or carry on with the order flow.',
    alt: 'A screenshot of the seller portal, showing the orders table',
    src: step1Img,
  },
  {
    title: 'Confirm order details',
    description:
      'Let us know what you will deliver and the shipping costs so we can pay you the correct amount.',
    alt: 'An image of the seller portal, showing the order detail page',
    src: step2Img,
  },
  {
    title: 'Provide shipping details',
    description:
      'Fill in the carrier name, URL and tracking numbers for each box/ pallet.',
    alt: 'An image of the seller portal, highlighting the shipping details section.',
    src: step3Img,
  },
  {
    title: 'Trigger payment',
    description:
      'Once you’re happy with all the information, trigger the payment, and you will be paid securely within seconds.',
    alt: 'An image of the seller portal, highlighting the trigger payment button.',
    src: step4Img,
  },
] as const

type Steps = 0 | 1 | 2 | 3

const useStep = () => {
  const [step, setStep] = useState<Steps>(0)

  const onNext = () => {
    setStep((previous) => {
      if (previous === steps.length - 1) {
        // reset to the first step
        return 0
      }
      return (previous + 1) as Steps
    })
    trackMarketingPagePortalNextStepClicked()
  }

  return [step, onNext] as const
}

/**
 * Absolute background to fill the parent container
 */
const AbsoluteBackground = () => {
  return (
    <div className="absolute inset-0 z-0 hidden grid-cols-2 grid-rows-1 md:grid">
      <div className="bg-primary-50" />
    </div>
  )
}

const StepContent = ({
  title,
  description,
  invisible,
}: {
  title: string
  description: string
  /** We use invisible step content to calculate the layout */
  invisible?: boolean
}) => {
  return (
    <div
      className={clsx('w-full shrink-0', invisible ? 'invisible' : 'absolute')}
    >
      <h3 className="q-text-header-3xl mb-4" aria-hidden={invisible}>
        {title}
      </h3>
      <p className="q-text-body-base-bold" aria-hidden={invisible}>
        {description}
      </p>
    </div>
  )
}

const StepImage = ({ step }: { step: number }) => {
  return (
    <>
      {steps.map(({ src, alt, title }, index) => {
        const isInvisible = index !== step
        return (
          <div
            key={title}
            className={clsx({
              'flex items-end': true,
              ['invisible absolute']: isInvisible,
            })}
            aria-hidden={isInvisible}
          >
            <Image loader={vercelImageLoader} src={src} alt={alt} />
          </div>
        )
      })}
    </>
  )
}

export const PortalPanel = (): JSX.Element => {
  const [step, onNext] = useStep()

  const { title, description } = steps[step]

  return (
    <PanelCentralizedContainer className="relative bg-white pt-24">
      <AbsoluteBackground />
      <div className="z-10 grid w-full grid-cols-1 gap-x-12 gap-y-4 md:grid-cols-2">
        <div className="relative hidden max-w-[440px] items-end justify-self-end md:flex">
          <StepImage step={step} />
        </div>
        <div>
          <h2 className="q-text-header-4xl mb-4">Seller portal</h2>
          <p className="q-text-body-base mb-10">
            Our seller portal is used by sellers to view past, current and new
            orders. It unifies the order process from order placed to order
            shipped, helping sellers to fulfill more and grow as a business.
            Discover the simple workflow that allows you to complete an order by
            clicking through the steps below. The seller portal will also
            regularly provide effective tips to easily sell more and grow your
            business.
          </p>
          <p className="q-text-body-base-bold text-primary-700 mb-4">
            Step {step + 1}
          </p>
          <div className="relative mb-8 flex flex-row overflow-hidden">
            <StepContent title={title} description={description} />
            {steps.map(({ title, description }) => (
              // Invisible steps are used to calculate layout of the container
              <StepContent
                key={title}
                title={title}
                description={description}
                invisible
              />
            ))}
          </div>
          <div className="mb-8 flex flex-row items-center gap-6">
            <ol className="flex flex-row gap-2">
              {steps.map(({ title }, index) => (
                <li
                  key={title}
                  className={clsx(
                    'border-primary-700 h-2 w-2 rounded-lg border',
                    { 'bg-primary-700': index === step },
                  )}
                  aria-current={index === step ? 'step' : undefined}
                />
              ))}
            </ol>
            <Button onClick={onNext} color="primary" appearance="text">
              Next
              <ArrowSmallRightIcon className="h-5 w-5" />
            </Button>
          </div>
        </div>
        <div className="relative flex max-w-[440px] justify-self-center md:hidden">
          <StepImage step={step} />
        </div>
      </div>
    </PanelCentralizedContainer>
  )
}
