import { Testimonial, Testimonials } from '@qogita/ui'

import { TrustBoxMicroTrustScore } from './components/Trustpilot/MicroTrustScore'

const BrastyGroupTestimonial = () => (
  <Testimonial>
    <Testimonial.Icon />
    <Testimonial.Content>
      Selling on Qogita has some awesome perks! You&apos;ll get more orders,
      which means more sales. Plus, the Qogita team is super easy to chat with,
      so you&apos;ll get all the help you need. And the best part? Your business
      can totally boom on this platform, opening doors for some serious growth
      opportunities!
    </Testimonial.Content>
    <Testimonial.Name>Radim Michalek</Testimonial.Name>
    <Testimonial.JobTitle>
      Sales manager at Brasty Group S.R.O
    </Testimonial.JobTitle>
  </Testimonial>
)

const ArrowBrandsTestimonial = () => (
  <Testimonial>
    <Testimonial.Icon />
    <Testimonial.Content>
      We have been customers of this platform for over a year and are extremely
      satisfied with the experience. It is a simple and intuitive way to do
      business, does not require any specific expertise and is therefore
      accessible to everyone. The platform works effectively in most cases, with
      only a few minor glitches from time to time, which is completely normal.
      We highly recommend this solution to both small and large enterprises, as
      it has provided us with an excellent business experience.
    </Testimonial.Content>
    <Testimonial.Name>Leonardo La Mura</Testimonial.Name>
    <Testimonial.JobTitle>
      Sales manager at Arrow Brands and Distributions S.R.L
    </Testimonial.JobTitle>
  </Testimonial>
)

const GabonaGroupAndTrenaTestimonial = () => (
  <Testimonial>
    <Testimonial.Icon />
    <Testimonial.Content>
      From my first collaboration with Qogita, I found their company to be ahead
      of the curve in many ways. Their strong point is the Portal they use for
      order management which allows us to interact with our order creation
      system in a fast, dynamic and precise way. As a wholesaler they have
      always been loyal, courteous and above all, professional in every aspect.
      The customer service provided by Qogita is exceptional, ensuring that any
      concerns or inquiries are promptly addressed and their level of customer
      service is remarkable. Moreover, the potential growth offered by Qogita is
      outstanding, with its wide reach and diverse network of businesses offered
      by its Portal, opening up endless possibilities for expansion and success.
      Based on my experience, I highly recommend Qogita as a loyal and trusted
      business partner and I am very satisfied with the way they conduct their
      business and hope that you have the same positive experience with them.
    </Testimonial.Content>
    <Testimonial.Name>Ewa Waszczuk</Testimonial.Name>
    <Testimonial.JobTitle>
      Sales manager at Gabona Group and Trena
    </Testimonial.JobTitle>
  </Testimonial>
)

const PombexPremiumSLTestimonial = () => (
  <Testimonial>
    <Testimonial.Icon />
    <Testimonial.Content>
      Without a doubt, using Qogita&apos;s website is very easy and convenient,
      you can easily modify any aspect of the sale and complete all the details
      until you finalize the payment yourself. Qogita&apos;s operations team are
      always available with support and supervision.
    </Testimonial.Content>
    <Testimonial.Name>Jesus Sanchez</Testimonial.Name>
    <Testimonial.JobTitle>Commercial at Pombex Premium SL</Testimonial.JobTitle>
  </Testimonial>
)

export const TestimonialsPanel = () => {
  return (
    <Testimonials>
      <Testimonials.Header>What our sellers are saying</Testimonials.Header>
      <TrustBoxMicroTrustScore />
      <Testimonials.Grid>
        <div className="mb:gap-20 flex flex-col gap-14">
          <BrastyGroupTestimonial />
          <ArrowBrandsTestimonial />
        </div>
        <div className="flex flex-col gap-20">
          <GabonaGroupAndTrenaTestimonial />
          <PombexPremiumSLTestimonial />
        </div>
      </Testimonials.Grid>
    </Testimonials>
  )
}
