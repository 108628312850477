import { Seo } from '#src/components/shared/Seo'
import { Page as NextPage } from '#types/next'

import { MarketingHomePage } from './MarketingHomePage'

const Page: NextPage = () => {
  return (
    <>
      <Seo title="Qogita" />

      <MarketingHomePage />
    </>
  )
}

export { Page }
