import { SvgAttribute } from '#components/shared/svgs/icon.interface'

export const SvgItsFree = (props: SvgAttribute): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="icons"
    viewBox="0 0 43.88 43.88"
    {...props}
  >
    <path
      className="cls-1"
      d="M42.85,0C32.52,0,22.69,4.88,16.43,13.1c-.82-.15-1.67-.23-2.53-.23C6.24,12.87,0,19.1,0,26.77,0,27.34,.46,27.8,1.03,27.8H10.23c.04,.1,.09,.19,.16,.28,1.6,2.02,3.43,3.84,5.42,5.42,.08,.07,.17,.12,.27,.15v9.2c0,.57,.46,1.03,1.03,1.03,7.67,0,13.9-6.24,13.9-13.9,0-.82-.08-1.67-.23-2.52,8.22-6.27,13.1-16.1,13.1-26.43C43.88,.46,43.42,0,42.85,0ZM2.11,25.73c.52-6.04,5.61-10.8,11.79-10.8,.4,0,.79,.02,1.18,.06-2.16,3.28-3.7,6.88-4.59,10.74H2.11Zm16.04,16.04v-8.38c3.84-.88,7.44-2.42,10.74-4.59,.04,.4,.06,.79,.06,1.18,0,6.18-4.76,11.26-10.8,11.79Zm10.9-15.6s-.02,.02-.04,.03c-3.64,2.65-7.7,4.45-12.08,5.34-.02,0-.03,0-.05,0-.05,.01-.1,.02-.16,.03-1.61-1.31-3.09-2.8-4.42-4.42l.03-.18s0-.02,0-.03c.91-4.41,2.7-8.48,5.34-12.08,0-.01,.02-.02,.03-.04,5.65-7.71,14.59-12.43,24.09-12.75-.33,9.5-5.04,18.44-12.75,24.09Z"
      fill="currentColor"
    />
    <path
      className="cls-1"
      d="M28.37,19.76c2.34,0,4.25-1.91,4.25-4.25s-1.91-4.25-4.25-4.25-4.25,1.91-4.25,4.25,1.91,4.25,4.25,4.25Zm0-6.43c1.2,0,2.18,.98,2.18,2.18s-.98,2.18-2.18,2.18-2.18-.98-2.18-2.18,.98-2.18,2.18-2.18Z"
      fill="currentColor"
    />
    <path
      className="cls-1"
      d="M11.16,36.74c-1.64,2.21-4.16,3.47-6.91,3.47-.19,0-.37,0-.56-.02-.01-.19-.02-.38-.02-.56,0-2.75,1.26-5.27,3.47-6.91,.46-.34,.55-.99,.21-1.45-.34-.46-.99-.55-1.45-.21-2.73,2.04-4.3,5.16-4.3,8.57,0,.5,.04,1.04,.12,1.63,.06,.45,.41,.81,.87,.88,.55,.09,1.09,.13,1.66,.13,3.41,0,6.53-1.57,8.57-4.3,.34-.46,.25-1.1-.21-1.45-.46-.34-1.11-.25-1.45,.21Z"
      fill="currentColor"
    />
  </svg>
)
