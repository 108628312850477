import Image from 'next/image'

import { SvgAttribute } from '#components/shared/svgs/icon.interface'
import { vercelImageLoader } from '#utils/url'

import orderShippingTableImage from '../assets/whoAreYou/orderShippingTable.svg'
import { PanelCentralizedContainer } from './components/PanelContainer'
import { RegisterButton } from './RegisterButton'

const CheckIcon = ({ className }: SvgAttribute): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    className={className}
  >
    <path
      d="M7 11.25L9.25 13.5L13 8.25M19 10.5C19 15.4706 14.9706 19.5 10 19.5C5.02944 19.5 1 15.4706 1 10.5C1 5.52944 5.02944 1.5 10 1.5C14.9706 1.5 19 5.52944 19 10.5Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

interface Content {
  title: string
  subtitle: string
  sellerRequirements: {
    value: string
  }[]
  buttonCaption: string
}

const content: Content = {
  title: 'Who are you?',
  subtitle: 'We work with sellers that can meet our basic requirements:',
  sellerRequirements: [
    {
      value: 'Hold inventory in your warehouse',
    },
    {
      value: 'Ship only fresh and original goods in perfect condition',
    },
    {
      value: 'Dispatch goods within a week',
    },
    {
      value: 'Communicate your updated stock lists regularly',
    },
    {
      value: 'Ability to ship to multiple addresses',
    },
  ],
  buttonCaption: 'Register to sell via Qogita',
}

function SellerRequirementsList() {
  return (
    <div className="mb-8 mt-4 flex flex-col gap-3 md:mb-0">
      {content.sellerRequirements.map((item) => (
        <div key={item.value} className="flex items-center gap-2">
          <CheckIcon className="text-primary-700 h-[18px] w-[18px]" />
          <h2 className="q-text-body-base">{item.value}</h2>
        </div>
      ))}
    </div>
  )
}

export function WhoAreYou() {
  return (
    <PanelCentralizedContainer className="items-start bg-gray-100 py-24 md:py-0">
      <div className="flex flex-col justify-between md:flex-row">
        <div className="flex flex-col px-0 pb-6 md:py-40">
          <h2 className="q-text-header-4xl">{content.title}</h2>
          <p className="q-text-body-base-bold mt-4 max-w-md text-gray-700">
            {content.subtitle}
          </p>
          <SellerRequirementsList />
          <RegisterButton
            color="primary"
            appearance="outlined"
            className="mt-6 hidden max-w-fit md:block"
          >
            {content.buttonCaption}
          </RegisterButton>
        </div>
        <Image
          loader={vercelImageLoader}
          src={orderShippingTableImage}
          alt="Three images of tables. Two show orders and shipping data and one shows information about product stock."
        />
      </div>
    </PanelCentralizedContainer>
  )
}
