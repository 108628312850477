import { SvgAttribute } from '#components/shared/svgs/icon.interface'

export const SvgExpandYourReach = (props: SvgAttribute): JSX.Element => (
  <svg
    width="49"
    height="49"
    xmlns="http://www.w3.org/2000/svg"
    id="icons"
    viewBox="0 0 60.39 51.46"
    {...props}
  >
    <path
      className="cls-1"
      d="M52.14,41.8c2.11-2.43,3.27-5.52,3.27-8.79,0-5.76-3.64-10.67-8.74-12.59,0-.02,0-.04,0-.06-.39-2.97-1.33-5.79-2.8-8.4,0-.01-.01-.02-.02-.04C39.7,4.57,31.87,0,23.44,0S7.18,4.57,3.02,11.92c0,.01-.01,.02-.02,.03C1.04,15.44,0,19.41,0,23.44,0,25.42,.25,27.4,.74,29.3c0,0,0,0,0,0,2.67,10.34,12,17.57,22.7,17.57,3.82,0,7.6-.95,10.95-2.72,2.15,1.46,4.75,2.32,7.55,2.32,3.27,0,6.35-1.15,8.79-3.27l7.96,7.96c.2,.2,.45,.29,.71,.29s.51-.1,.71-.29c.39-.39,.39-1.02,0-1.41l-7.96-7.96Zm-7.59-21.99c-.85-.17-1.72-.26-2.61-.26-2.3,0-4.46,.58-6.35,1.6-.05-.96-.12-1.91-.22-2.84,2.61-1.09,5.08-2.54,7.32-4.33,.91,1.84,1.52,3.8,1.87,5.83Zm-16.08,13.21c0,.12,.01,.23,.02,.35-4.73,.54-9.59,.32-14.24-.67-.66-2.82-1.04-5.96-1.04-9.25,0-1.5,.08-2.97,.23-4.4,6.38,2.18,13.6,2.18,19.99,0,.11,1.08,.18,2.2,.21,3.33,0,.02,.01,.03,.01,.05-3.15,2.47-5.18,6.29-5.18,10.59Zm13.22-20.81c-2.03,1.65-4.26,3.01-6.62,4.05-.97-5.81-3.08-10.6-5.82-13.44,5.12,1.45,9.59,4.77,12.44,9.4ZM23.44,2c4.47,0,8.4,6.37,9.74,15.01-6.2,2.22-13.27,2.22-19.47,0C15.04,8.42,18.96,2,23.44,2Zm-5.92,.83c-2.73,2.84-4.79,7.6-5.74,13.41-2.36-1.04-4.58-2.4-6.61-4.04,2.83-4.59,7.27-7.91,12.35-9.37ZM2,23.44c0-3.29,.76-6.53,2.19-9.46,2.24,1.78,4.7,3.23,7.31,4.32-.18,1.65-.28,3.37-.28,5.14,0,3.1,.3,6.04,.84,8.72-3.3-.88-6.49-2.15-9.49-3.77-.38-1.61-.57-3.28-.57-4.95Zm1.39,7.62c2.93,1.43,6.02,2.53,9.19,3.3,1.11,4.12,2.83,7.49,4.95,9.69-6.43-1.84-11.73-6.62-14.14-13Zm20.05,13.82c-3.56,0-6.76-4.05-8.6-10.03,4.54,.86,9.26,1.03,13.85,.5,.26,1.47,.75,2.86,1.44,4.13-1.89,3.47-4.25,5.4-6.7,5.4Zm5.89-.82c.73-.76,1.41-1.67,2.05-2.71,.4,.51,.85,.99,1.32,1.44-1.08,.52-2.21,.95-3.37,1.27Zm20.72-2.94h0c-2.15,2.17-5.03,3.37-8.1,3.37-4.07,0-7.64-2.14-9.68-5.35-.05-.14-.13-.27-.23-.38-.98-1.69-1.55-3.64-1.55-5.73,0-6.32,5.14-11.47,11.47-11.47s11.47,5.14,11.47,11.47c0,3.07-1.19,5.95-3.36,8.1Z"
      fill="currentColor"
    />
  </svg>
)
