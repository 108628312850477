export const INPUT_HEIGHT = 'h-10'

/**
 * ### This is the unique id from typeform for the new seller form.
 *  This specific id is from https://qogita.typeform.com/newsellerform. Ask product for this id.
 *
 **/
export const NEW_SELLER_FORM_TYPEFORM_ID = 'pWDnXPe4'

// const ONE_MB_IN_BINARY = 1024 * 1024
export const ONE_MB_IN_DECIMAL = 1000 * 1000
export const TEN_MB = ONE_MB_IN_DECIMAL * 10
export const TWENTY_FIVE_MB = ONE_MB_IN_DECIMAL * 25

export const PAGE_SIZE = 20

/**
 * Set to 200 to make sure we cover the biggest sale ever.
 * The avg sale has 4 items, and the biggest sale had 131 items.
 */
export const SALE_LINES_PAGE_SIZE = 200

export const UI_DEBOUNCE = 200

export const COPY_ICON_TIMEOUT = 3000

export const NOT_FOUND_INDEX = -1
